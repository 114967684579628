import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { history } from 'index'

import errors from 'utils/errors'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

API.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

API.interceptors.response.use(undefined, error => {
  const { response } = error
  const { data } = response

  if (data && data?.message) {
    notification.warning({
      message: errors.formatInterceptorsErrorMessage(data.message),
    })
  }
  if (response.status === 401) {
    history.push('/auth/login')
  }
})

export default API
