export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboard Resprie Bem',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'fa fa-desktop',
      url: '/bem-vindo',
    },
    {
      title: 'Relatórios',
      key: 'resports',
      icon: 'fa fa-line-chart',
      url: '/reports',
      roles: ['Admin'],
    },
    {
      title: 'Meus dados',
      key: 'myInformation',
      icon: 'fa fa-user',
      url: '/meus-dados',
      roles: ['médico'],
    },
    {
      title: 'Pacientes',
      key: 'patients',
      icon: 'fa fa-users',
      children: [
        {
          title: 'Lista de pacientes',
          key: 'listPatients',
          url: '/pacientes/lista',
        },
      ],
    },

    {
      title: 'Artigos',
      key: 'posts',
      icon: 'fa fa-share-square-o',
      roles: ['Admin'],
      children: [
        {
          title: 'Lista de artigos',
          key: 'listPosts',
          url: '/artigos/lista',
        },
        {
          title: 'Criar artigo',
          key: 'createPost',
          url: '/artigos/criar',
        },
        {
          title: 'Lista de categorias',
          key: 'listCategories',
          url: '/categoria/lista',
        },
        {
          title: 'Criar categoria',
          key: 'registerCategories',
          url: '/categoria/criar',
        },
      ],
    },
  ]
}
