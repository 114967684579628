function formatInterceptorsErrorMessage(message = {}) {
  if (!message) return ''
  if (typeof message === 'string') return message
  if (Array.isArray(message)) return 'Ocorreu um erro no retorno das informações.'

  const mess = Object.values(message).join(', ')

  return mess
}

export default { formatInterceptorsErrorMessage }
