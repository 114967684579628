import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/admin')
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function logout() {
  return apiClient
    .get('api/admin/user/logout')
    .then(() => {
      store.remove('accessToken')
      store.remove('user')
      return true
    })
    .catch(err => console.log(err))
}
